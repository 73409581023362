import React from 'react'
import {
    Box,
    Flex,
    Button,
    Text,
    Link
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { ChevronRightIcon } from '@chakra-ui/icons'

const Annoucement = ({ slice }) => (
  
<Box bg="steel.500"
        p="4"
        color="white"
        marginTop={{sm: '90px', md: 0}}
        display="block">
    <Flex justifyContent="center"
      alignItems="center">
      <Box mr="4"
        display={{sm: 'none', md: 'block'}}
      >
        <Link as={GatsbyLink} to="/news-events">
          <Button borderRadius="50px"
            fontSize="1rem"
            fontWeight="500"
            padding="0px 20px 0px 20px"
            bg="steel.400"
            textTransform="uppercase"
            >
            Spotlight
          </Button>
        </Link>
      </Box>
      <Box>
        <Text mb="0" w="100%">
        {slice.primary.announcement_text.text} - <Link as={GatsbyLink} to="/news-events" _hover={{color: 'steel.700'}}><strong>Read More <ChevronRightIcon /></strong></Link>
        </Text>
      </Box>
    </Flex>
  </Box>
)

export default Annoucement;