
import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'


const Logo = styled.svg `
  width: ${props => props.width ? props.width : '75px' };
  height: auto;
  .cls-1 {
        fill: #004f7e;
      }

      .cls-2 {
        fill: #739bbd;
      }

`;

const IrisBiomedicalLogo = (props) => {
    return (
  <Link to="/">

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.00446 73.05144" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <g>
            <path className="cls-1" d="M.2803,38.89548v-38.4h7.775v38.4Z"/>
            <path className="cls-1" d="M13.9343,38.89548v-38.4h9.956a22.24693,22.24693,0,0,1,6.661.877,7.776,7.776,0,0,1,4.267,3.105,10.97393,10.97393,0,0,1,1.493,6.163,19.61262,19.61262,0,0,1-.4,4.2,8.09993,8.09993,0,0,1-1.422,3.176,6.99993,6.99993,0,0,1-2.868,2.157l5.31,18.727h-8.012l-4.409-17.4h-2.75v17.4Zm7.823-22.049h2.277a6.1,6.1,0,0,0,2.987-.616,3.376,3.376,0,0,0,1.541-1.873,9.283,9.283,0,0,0,.45-3.105,7.078,7.078,0,0,0-.972-4.054q-.972-1.4-3.627-1.4h-2.655Z"/>
            <path className="cls-1" d="M41.52729,38.89548v-38.4h7.775v38.4Z"/>
            <path className="cls-1" d="M65.13732,39.41648a12.179,12.179,0,0,1-5.926-1.3,8.28609,8.28609,0,0,1-3.58-3.935,18.1821,18.1821,0,0,1-1.4-6.709l6.732-1.138a19.57164,19.57164,0,0,0,.5,3.959,4.8001,4.8001,0,0,0,1.233,2.37,2.889,2.889,0,0,0,2.062.782,2.171,2.171,0,0,0,2.062-.948,4.3,4.3,0,0,0,.545-2.181,6.82389,6.82389,0,0,0-1.162-4.054,19.40071,19.40071,0,0,0-3.058-3.3l-3.982-3.461a16.039,16.039,0,0,1-3.485-4.077,10.78506,10.78506,0,0,1-1.351-5.594q0-4.74,2.773-7.277a10.85405,10.85405,0,0,1,7.609-2.536,10.3731,10.3731,0,0,1,4.741.948,7.25579,7.25579,0,0,1,2.868,2.536,10.42362,10.42362,0,0,1,1.422,3.461,23.47091,23.47091,0,0,1,.5,3.722l-6.685,1a29.94577,29.94577,0,0,0-.308-3.082,4.215,4.215,0,0,0-.853-2.086,2.374,2.374,0,0,0-1.92-.759,2.262,2.262,0,0,0-2.039,1.019,4.039,4.039,0,0,0-.664,2.252,5.64406,5.64406,0,0,0,.924,3.342,16.33929,16.33929,0,0,0,2.536,2.726l3.888,3.414a22.13841,22.13841,0,0,1,4.148,4.765,11.94821,11.94821,0,0,1,1.73,6.614,9.75014,9.75014,0,0,1-1.233,4.883,8.812,8.812,0,0,1-3.43512,3.408A10.47305,10.47305,0,0,1,65.13732,39.41648Z"/>
          </g>
          <g>
            <path className="cls-2" d="M.8073,71.60647a.836.836,0,0,1-.576-.2.735.735,0,0,1-.23-.579.766.766,0,0,1,.4-.7,3.33,3.33,0,0,1,1.289-.338,1.27322,1.27322,0,0,0-.026-.256.64041.64041,0,0,0-.088-.221.463.463,0,0,0-.173-.156.58915.58915,0,0,0-.281-.06,1.1191,1.1191,0,0,0-.449.091,2.18929,2.18929,0,0,0-.375.2l-.182-.324a3.01594,3.01594,0,0,1,.471-.241,1.575,1.575,0,0,1,.613-.116.848.848,0,0,1,.733.31,1.367,1.367,0,0,1,.227.826v1.692h-.386l-.04-.329h-.017a2.33527,2.33527,0,0,1-.426.281A1.05614,1.05614,0,0,1,.8073,71.60647Zm.136-.375a.78714.78714,0,0,0,.375-.094,1.88372,1.88372,0,0,0,.375-.275v-.763a4.73788,4.73788,0,0,0-.582.108,1.53859,1.53859,0,0,0-.38.148.57194.57194,0,0,0-.21.2.466.466,0,0,0-.065.241.391.391,0,0,0,.142.341.577.577,0,0,0,.347.1Z"/>
            <path className="cls-2" d="M6.0563,68.00249l-.176.707-.079-.023a1.728,1.728,0,0,0-.365-.07.555.555,0,0,0-.533.389,1.643,1.643,0,0,0-.13.692v1.922h-.722v-3.65h.715v.31a1.021,1.021,0,0,1,.817-.372,1.309,1.309,0,0,1,.4.072Z"/>
            <g>
              <path className="cls-2" d="M7.4253,67.33648a.47192.47192,0,0,1-.134.327h0a.464.464,0,1,1,.138-.329Z"/>
              <rect className="cls-2" x="6.5913" y="67.96948" width="0.726" height="3.65"/>
            </g>
            <path className="cls-2" d="M11.4103,68.46947a1.685,1.685,0,0,0-1.335-.584,1.59984,1.59984,0,0,0-.749.191,1.36774,1.36774,0,0,0-.323.24v-.346h-.715v5.081h.726v-1.766a1.53693,1.53693,0,0,0,1.1.437,1.68412,1.68412,0,0,0,.724-.153,1.63107,1.63107,0,0,0,.577-.456,2.129,2.129,0,0,0-.006-2.645Zm-1.346,2.584a.96406.96406,0,0,1-.811-.367,1.447,1.447,0,0,1-.278-.938,1.2,1.2,0,0,1,.331-.887.93309.93309,0,0,1,.707-.314,1.04394,1.04394,0,0,1,.851.361h0a1.28793,1.28793,0,0,1,.276.856,1.456,1.456,0,0,1-.274.888.943.943,0,0,1-.802.401Z"/>
            <path className="cls-2" d="M15.7703,68.46847a1.682,1.682,0,0,0-1.335-.584,1.60817,1.60817,0,0,0-.749.191,1.32074,1.32074,0,0,0-.321.24v-.346h-.715v5.081h.726v-1.766a1.53,1.53,0,0,0,1.1.437,1.68591,1.68591,0,0,0,.726-.153,1.63092,1.63092,0,0,0,.577-.456A2.133,2.133,0,0,0,15.7703,68.46847Zm-.268,1.3a1.456,1.456,0,0,1-.274.888.938.938,0,0,1-.8.4.958.958,0,0,1-.809-.367,1.44693,1.44693,0,0,1-.278-.938,1.21,1.21,0,0,1,.329-.887.936.936,0,0,1,.707-.314,1.04793,1.04793,0,0,1,.853.361,1.288,1.288,0,0,1,.272.854Z"/>
            <rect className="cls-2" x="17.0123" y="67.10548" width="0.726" height="4.514"/>
            <path className="cls-2" d="M21.99129,69.88045a2.194,2.194,0,0,0-.444-1.416,1.527,1.527,0,0,0-1.255-.58,1.638,1.638,0,0,0-1.295.578,1.98193,1.98193,0,0,0-.48,1.336,1.96,1.96,0,0,0,.48,1.338,1.782,1.782,0,0,0,2.9-.416l.034-.072-.624-.323-.032.087a1.042,1.042,0,0,1-.363.456.98192.98192,0,0,1-.582.2,1.00006,1.00006,0,0,1-.8-.363,1.215,1.215,0,0,1-.285-.745h2.752Zm-1.705-1.361a.90706.90706,0,0,1,.694.284,1.11289,1.11289,0,0,1,.263.563h-1.953a1.04512,1.04512,0,0,1,.3-.558A.96692.96692,0,0,1,20.28629,68.51946Z"/>
            <path className="cls-2" d="M27.7123,67.96947l-1.826,3.951-1.832-3.951h.794l1.038,2.376,1.043-2.376Z"/>
            <path className="cls-2" d="M31.33931,69.88146a2.2,2.2,0,0,0-.442-1.416,1.53294,1.53294,0,0,0-1.257-.58,1.638,1.638,0,0,0-1.295.578,1.98191,1.98191,0,0,0-.48,1.336,1.95994,1.95994,0,0,0,.48,1.338,1.726,1.726,0,0,0,2.287.27,1.7,1.7,0,0,0,.609-.686l.034-.072-.622-.323-.034.087a1.02807,1.02807,0,0,1-.363.456.9819.9819,0,0,1-.582.2,1,1,0,0,1-.8-.363,1.21508,1.21508,0,0,1-.285-.745h2.752Zm-1.705-1.361a.89994.89994,0,0,1,.694.284,1.11286,1.11286,0,0,1,.263.563h-1.953a1.045,1.045,0,0,1,.3-.558.96693.96693,0,0,1,.694-.289Z"/>
            <path className="cls-2" d="M35.1003,69.04546v2.575h-.726v-2.421a.541.541,0,0,0-.622-.622.79994.79994,0,0,0-.663.293,1.114,1.114,0,0,0-.221.72v2.03h-.726v-3.65h.709v.336a1.24805,1.24805,0,0,1,.964-.422,1.38285,1.38285,0,0,1,.9.291A1.089,1.089,0,0,1,35.1003,69.04546Z"/>
            <path className="cls-2" d="M37.3833,67.96847v.658h-.609v2.992h-.726v-2.99h-.408v-.66h.408v-.864h.726v.864Z"/>
            <path className="cls-2" d="M40.93231,67.97048v2.444a1.2,1.2,0,0,1-.433.977,1.51908,1.51908,0,0,1-1.019.331,1.6511,1.6511,0,0,1-1.043-.306,1.19006,1.19006,0,0,1-.435-.991v-2.456h.726v2.45c0,.448.244.656.769.656a.77.77,0,0,0,.522-.174.589.589,0,0,0,.2-.476v-2.456Z"/>
            <path className="cls-2" d="M43.91229,68.00249l-.174.707-.081-.023a1.70626,1.70626,0,0,0-.365-.07.555.555,0,0,0-.533.389,1.67518,1.67518,0,0,0-.129.692v1.922h-.726v-3.65h.715v.31a1.01692,1.01692,0,0,1,.815-.372,1.31934,1.31934,0,0,1,.406.072Z"/>
            <path className="cls-2" d="M47.6163,69.88045a2.19385,2.19385,0,0,0-.444-1.416,1.53,1.53,0,0,0-1.257-.58,1.634,1.634,0,0,0-1.293.578,1.974,1.974,0,0,0-.482,1.336,1.952,1.952,0,0,0,.482,1.338,1.678,1.678,0,0,0,1.316.584,1.834,1.834,0,0,0,1.578-1l.036-.072-.624-.323-.032.087a1.04716,1.04716,0,0,1-.365.456.982.982,0,0,1-.582.2,1,1,0,0,1-.8-.363,1.19988,1.19988,0,0,1-.284-.745h2.752Zm-1.707-1.361a.9.9,0,0,1,.694.284,1.112,1.112,0,0,1,.263.563h-1.953a1.03594,1.03594,0,0,1,.3-.558A.96009.96009,0,0,1,45.9093,68.51946Z"/>
            <path className="cls-2" d="M50.49628,70.57247a1.057,1.057,0,0,1-.382.853,1.327,1.327,0,0,1-.864.3,1.219,1.219,0,0,1-1.195-.752l-.032-.07.616-.327.036.079a.593.593,0,0,0,.563.406.585.585,0,0,0,.4-.121.373.373,0,0,0,.132-.3.483.483,0,0,0-.342-.465c-.115-.051-.3-.136-.56-.255a1.056,1.056,0,0,1-.671-.994.9819.9819,0,0,1,.314-.741,1.15505,1.15505,0,0,1,.807-.295,1.086,1.086,0,0,1,1.053.733l.026.074-.609.27-.03-.079a.459.459,0,0,0-.452-.352.384.384,0,0,0-.28.1.324.324,0,0,0-.11.253.361.361,0,0,0,.134.284,1.64057,1.64057,0,0,0,.357.2l.34.159A1.10605,1.10605,0,0,1,50.49628,70.57247Z"/>
            <path className="cls-2" d="M53.57129,71.6065a1.3496,1.3496,0,0,1-.511-.1,1.14611,1.14611,0,0,1-.412-.281,1.31906,1.31906,0,0,1-.273-.454,1.75639,1.75639,0,0,1-.1-.611,1.64785,1.64785,0,0,1,.108-.616,1.38614,1.38614,0,0,1,.29-.457,1.208,1.208,0,0,1,.423-.284,1.35629,1.35629,0,0,1,.508-.1,1.0319,1.0319,0,0,1,.466.1,1.57853,1.57853,0,0,1,.341.227l-.238.307a1.17516,1.17516,0,0,0-.253-.176.64718.64718,0,0,0-.3-.068.775.775,0,0,0-.619.3,1.08077,1.08077,0,0,0-.179.338,1.36769,1.36769,0,0,0-.065.434,1.40021,1.40021,0,0,0,.062.432,1.04323,1.04323,0,0,0,.173.332.76589.76589,0,0,0,.27.216.78718.78718,0,0,0,.346.077.77213.77213,0,0,0,.355-.082,1.43953,1.43953,0,0,0,.293-.2l.2.312a1.47647,1.47647,0,0,1-.415.258A1.23914,1.23914,0,0,1,53.57129,71.6065Z"/>
            <path className="cls-2" d="M56.02429,71.60747a1.24314,1.24314,0,0,1-.486-.1,1.19408,1.19408,0,0,1-.406-.281,1.36608,1.36608,0,0,1-.281-.454,1.66925,1.66925,0,0,1-.105-.611,1.68837,1.68837,0,0,1,.105-.616,1.39961,1.39961,0,0,1,.281-.457,1.17838,1.17838,0,0,1,.406-.284,1.269,1.269,0,0,1,.971,0,1.17906,1.17906,0,0,1,.406.284,1.39946,1.39946,0,0,1,.281.457,1.69089,1.69089,0,0,1,.105.616,1.67334,1.67334,0,0,1-.105.611,1.3701,1.3701,0,0,1-.281.454,1.194,1.194,0,0,1-.406.281A1.24284,1.24284,0,0,1,56.02429,71.60747Zm0-.386a.69179.69179,0,0,0,.324-.077.74391.74391,0,0,0,.253-.216,1.0273,1.0273,0,0,0,.162-.332,1.52961,1.52961,0,0,0,.057-.432,1.5619,1.5619,0,0,0-.057-.434,1.05852,1.05852,0,0,0-.162-.338.73439.73439,0,0,0-.253-.219.722.722,0,0,0-.647,0,.73322.73322,0,0,0-.253.219,1.05361,1.05361,0,0,0-.162.338,1.55707,1.55707,0,0,0-.057.434,1.52494,1.52494,0,0,0,.057.432,1.02225,1.02225,0,0,0,.162.332.74278.74278,0,0,0,.253.216.69191.69191,0,0,0,.323.076Z"/>
            <path className="cls-2" d="M58.02829,71.53847v-2.76h.386l.04.4h.017a1.90724,1.90724,0,0,1,.395-.332.85494.85494,0,0,1,.463-.133.791.791,0,0,1,.5.139.8079.8079,0,0,1,.264.389,2.32738,2.32738,0,0,1,.434-.383.842.842,0,0,1,.474-.145.745.745,0,0,1,.633.273,1.32009,1.32009,0,0,1,.207.806v1.749h-.466v-1.687a.98708.98708,0,0,0-.125-.565.44394.44394,0,0,0-.386-.173,1.024,1.024,0,0,0-.693.426v2h-.466v-1.687a.98708.98708,0,0,0-.125-.565.451.451,0,0,0-.392-.173,1.024,1.024,0,0,0-.693.426v2Z"/>
            <path className="cls-2" d="M62.7373,72.70248v-3.924h.386l.04.318h.017a2.09978,2.09978,0,0,1,.412-.273,1.02388,1.02388,0,0,1,.468-.114,1.067,1.067,0,0,1,.471.1.916.916,0,0,1,.341.284,1.33257,1.33257,0,0,1,.207.443,2.19984,2.19984,0,0,1,.071.582,1.89275,1.89275,0,0,1-.1.628,1.45687,1.45687,0,0,1-.261.469,1.10488,1.10488,0,0,1-.38.292,1.075,1.075,0,0,1-.454.1.92976.92976,0,0,1-.383-.085,1.93381,1.93381,0,0,1-.383-.233l.011.483v.931Zm1.141-1.488a.6641.6641,0,0,0,.315-.077.75294.75294,0,0,0,.25-.216,1.05606,1.05606,0,0,0,.165-.344,1.64485,1.64485,0,0,0,.06-.46,2.00524,2.00524,0,0,0-.04-.415.92841.92841,0,0,0-.128-.321.61406.61406,0,0,0-.227-.207.7.7,0,0,0-.332-.074.74211.74211,0,0,0-.355.1,2.07771,2.07771,0,0,0-.383.278v1.448a1.23916,1.23916,0,0,0,.363.224A.9129.9129,0,0,0,63.8783,71.21447Z"/>
            <path className="cls-2" d="M66.44531,71.60647a.83605.83605,0,0,1-.576-.2.735.735,0,0,1-.23-.579.766.766,0,0,1,.4-.7,3.32986,3.32986,0,0,1,1.289-.338,1.27467,1.27467,0,0,0-.026-.256.64026.64026,0,0,0-.088-.221.46309.46309,0,0,0-.173-.156.58917.58917,0,0,0-.281-.06,1.11912,1.11912,0,0,0-.449.091,2.19348,2.19348,0,0,0-.375.2l-.182-.324a3.01345,3.01345,0,0,1,.471-.241,1.57394,1.57394,0,0,1,.613-.116.848.848,0,0,1,.733.31,1.36712,1.36712,0,0,1,.227.826v1.692h-.378l-.04-.329h-.017a2.33649,2.33649,0,0,1-.426.281A1.056,1.056,0,0,1,66.44531,71.60647Zm.136-.375a.78714.78714,0,0,0,.375-.094,1.87877,1.87877,0,0,0,.375-.275v-.763a4.73753,4.73753,0,0,0-.582.108,1.53616,1.53616,0,0,0-.38.148.57286.57286,0,0,0-.21.2.46613.46613,0,0,0-.065.241.39094.39094,0,0,0,.142.341.57707.57707,0,0,0,.346.1Z"/>
            <path className="cls-2" d="M68.6713,71.53847v-2.76h.386l.04.4h.017a2.24873,2.24873,0,0,1,.417-.332.95718.95718,0,0,1,.508-.133.742.742,0,0,1,.639.273,1.348,1.348,0,0,1,.2.806v1.749h-.466v-1.687a.98708.98708,0,0,0-.125-.565.457.457,0,0,0-.4-.173.6788.6788,0,0,0-.38.108,2.46822,2.46822,0,0,0-.375.318v2Z"/>
            <path className="cls-2" d="M71.8233,72.72549a1.01029,1.01029,0,0,1-.176-.014.70849.70849,0,0,1-.148-.043l.091-.369.1.026a.44875.44875,0,0,0,.108.014.5.5,0,0,0,.389-.168,1.18426,1.18426,0,0,0,.236-.423l.062-.2-1.107-2.766h.483l.562,1.528q.062.182.133.383t.134.395h.023q.062-.187.119-.392t.114-.386l.494-1.528h.454l-1.039,2.987a3.21938,3.21938,0,0,1-.165.38,1.25678,1.25678,0,0,1-.216.3.96079.96079,0,0,1-.284.2A.87008.87008,0,0,1,71.8233,72.72549Z"/>
          </g>
          <rect className="cls-2" x="0.2413" y="62.21548" width="73.248" height="0.872"/>
        </g>
        <g>
          <path className="cls-1" d="M.0963,45.93047h2.707c1.847,0,3.139.625,3.139,2.222a2.011,2.011,0,0,1-1.333,1.986v.055a2.1,2.1,0,0,1,1.847,2.18c0,1.792-1.417,2.667-3.444,2.667H.0963Zm2.514,3.875c1.542,0,2.181-.569,2.181-1.5,0-1.056-.722-1.444-2.139-1.444H1.2633v2.944Zm.236,4.305c1.556,0,2.458-.556,2.458-1.778,0-1.125-.875-1.639-2.458-1.639H1.2633v3.421Z"/>
          <path className="cls-1" d="M8.6093,45.93047h1.17v9.111h-1.17Z"/>
          <path className="cls-1" d="M12.0863,50.44448c0-2.917,1.6-4.68,3.889-4.68s3.9,1.778,3.9,4.68c0,2.93-1.611,4.764-3.9,4.764S12.0863,53.37447,12.0863,50.44448Zm6.583,0c0-2.264-1.07-3.653-2.694-3.653s-2.68,1.389-2.68,3.653,1.056,3.736,2.68,3.736,2.69-1.459,2.69-3.736Z"/>
          <path className="cls-1" d="M22.16431,45.93047h1.458l1.722,4.875c.222.639.417,1.222.611,1.9h.055c.208-.681.375-1.264.6-1.9l1.722-4.875h1.458v9.111h-1.1v-5.027c0-.806.1-1.93.167-2.722h-.056l-.708,2.125-1.708,4.708h-.847l-1.694-4.708-.722-2.125h-.056c.07.792.167,1.917.167,2.722v5.028h-1.07Z"/>
          <path className="cls-1" d="M32.62129,45.93047h5.25v.986h-4.083v2.847h3.444v.986h-3.444v3.305h4.222v.986h-5.388Z"/>
          <path className="cls-1" d="M40.28729,45.93047h2.278c2.805,0,4.305,1.625,4.305,4.514s-1.5,4.6-4.25,4.6h-2.333Zm2.194,8.153c2.125,0,3.18-1.333,3.18-3.639s-1.056-3.555-3.18-3.555h-1.028v7.194Z"/>
          <path className="cls-1" d="M49.18629,45.93047h1.167v9.111h-1.167Z"/>
          <path className="cls-1" d="M52.6613,50.48547c0-2.93,1.722-4.722,4.042-4.722a3.38,3.38,0,0,1,2.514,1.125l-.639.722a2.49988,2.49988,0,0,0-1.861-.819c-1.708,0-2.847,1.4-2.847,3.666,0,2.292,1.083,3.722,2.805,3.722a2.709,2.709,0,0,0,2.111-.986l.639.694a3.523,3.523,0,0,1-2.792,1.319C54.3553,55.20748,52.6613,53.45846,52.6613,50.48547Z"/>
          <path className="cls-1" d="M63.32732,45.93047h1.333l3.069,9.111h-1.236l-.861-2.764h-3.319l-.875,2.764h-1.18Zm-.708,5.4h2.722l-.431-1.375c-.333-1.014-.625-2.014-.917-3.069h-.056c-.278,1.055-.569,2.055-.889,3.069Z"/>
          <path className="cls-1" d="M69.3683,45.93047h1.167v8.125h3.972v.986h-5.139Z"/>
        </g>
      </g>
    </g>
  </g>
</Logo>
</Link>
    )
}
export default IrisBiomedicalLogo
