import React, { useState } from "react"
import { Box, Link, Flex, Text, CloseButton, IconButton } from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import { Link as GatsbyLink } from "gatsby"
import { motion } from "framer-motion"
import ContactModal from "../ContactForm/ContactModal"

import IrisBiomedicalLogo from "./IrisBiomedicalLogo"

const MotionBox = motion(Box)

const MobileMenu = (props) => {
  const [show, setShow] = useState(false)
  const handleToggle = () => (
      setShow(!show)
  )

  return (
  <Box display={{ sm: 'block', md: 'block', lg: 'none' }} position="fixed" top="0" className="mobile-header">
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      paddingLeft="0.75rem"
      paddingRight="0.75rem"
      bg="shadow.100"  
      color="steel.400"
      {...props}
      display="flex"
    >
      
      <Flex w="75%">
        <Link
          as={GatsbyLink}
          to="/"
          color="white"
          _hover={{ textDecor: "none" }}
        >
          <MotionBox
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{ duration: 0.5 }}
            p={2}
          >
            
            <IrisBiomedicalLogo width="75px" />
          
          </MotionBox>
        </Link>
      </Flex>
    
    <div>
      <Box onClick={handleToggle}>
          { show ? <CloseButton /> : 
         ( <IconButton
            aria-label="Menu"
            icon={<HamburgerIcon />}
            variant="outline"
            size="md"
        />)
  }      </Box>
</div>    
    <Box
        display={{ sm: show ? "block" : "none"}}
        width={{ sm: "full", md: "full" }}
        alignItems="center"
        flexGrow={1}
        paddingBottom="1rem"
    >
        <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
        <Link to="/services" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            Services
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
        <Link to="/technology" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            Technology
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
        <Link to="/news-events#news" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            News
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
        <Link to="/news-events#events" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            Events
        </Link>
        </Text>
        <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
        <Link to="/about-us" as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="active">
            About
        </Link>
        </Text>
        <Box textAlign="center">
          <ContactModal />
          </Box>
   </Box>
    </Flex>
    </Box>
  )
}

export default MobileMenu