import React from 'react'
import HeaderAnnouncement from '../slices/HeaderAnnouncement'

const SliceZone = ({ sliceZone }) => {

    const sliceComponents = {
        header_announcement: HeaderAnnouncement,
    }

    const sliceZoneContent = sliceZone.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]
        if (SliceComponent) {
          return <SliceComponent slice={slice} key={`slice-${index}`} />
        }
        return null
      })
      return <div>{sliceZoneContent}</div>
    }

export default SliceZone