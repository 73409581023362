import React from "react"
import { Box, Flex, Container } from "@chakra-ui/react"
import { graphql, useStaticQuery } from 'gatsby'

import HomeNavMenu from "./NavMenu/HomeNavMenu"
import MobileMenu from "./NavMenu/MobileMenu"
import SliceZone from "./SliceZone/SliceZone"

const Header = () => {
  
  const data = useStaticQuery(graphql`
  query HeaderAnnouncement {
    allPrismicHomepageContent {
      edges {
        node {
          data {
            body {
              ... on PrismicHomepageContentDataBodyHeaderAnnouncement {
                id
                primary {
                  announcement_text {
                    text
                  }
                  announcement_link {
                    url
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
   
  `)

const document = data.allPrismicHomepageContent.edges[0].node.data
 return (
    <header>
        <Box display={{md: 'none', lg: 'block'}}>
          <SliceZone sliceZone={document.body} />
        </Box>
     <Flex>
        <Container
          centerContent
          maxW="100%"
          justifyContent="center"
          h="100%"
          flexWrap="wrap"
          bg="shadow.100"   
        >
        <HomeNavMenu />
        </Container>
      </Flex>
      <MobileMenu />
    </header>
  )
}

export default Header
