import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  Box,
  Flex,
  Link,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  MenuDivider,
  useDisclosure
} from "@chakra-ui/react"
import IrisBiomedicalLogo from "./IrisBiomedicalLogo"
import ContactModal from "../ContactForm/ContactModal"
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons"

const NavItem = props => {
  return (
    
      <Link
        as={GatsbyLink}
        variant="nav-item"
        _hover={{ textDecoration: "none", color: "steel.500" }}
        to={props.linkTo}
        activeClassName="active"
        //fontSize={{ lg: '.8rem', xl: '1rem'}}
      >
        {props.linkText}
      </Link>
  
  )
}

const StickyNavMenu = () => {
  const { isOpen: isServicesOpen, onOpen: onServicesOpen, onClose: onServicesClose } = useDisclosure();
  const { isOpen: isTechnologyOpen, onOpen: onTechnologyOpen, onClose: onTechnologyClose } = useDisclosure();
  const { isOpen: isNewsOpen, onOpen: onNewsOpen, onClose: onNewsClose } = useDisclosure();
  const { isOpen: isAboutOpen, onOpen: onAboutOpen, onClose: onAboutClose } = useDisclosure();

  return (
    <Flex
      w="100%"
      justifyContent={"space-around"}
      alignItems={"center"}
      className="desktopNav"
      id="nav-links"
      display={{ sm: "none", md: "none", lg: "flex", xl: "flex" }}
      //marginTop={{ lg: '-2rem', xl: '0'}}
    >
      <Box display={{ md: "none", lg: "block" }} my="6" w="35%">
        <IrisBiomedicalLogo width="100px" />
      </Box>
      {/* <ServicesMenu /> */}
      <Menu isOpen={isServicesOpen} onClose={onServicesClose}>
        
          <>
          <LinkBox>
          <LinkOverlay as={GatsbyLink} to="/services">
            <MenuButton
              as={Button}
              rightIcon={isServicesOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
              isActive={isServicesOpen}
              onMouseEnter={onServicesOpen}
              onMouseLeave={onServicesClose}
              bg="none"
            >
              <NavItem linkText="Services" linkTo="/services" />
            </MenuButton>
            </LinkOverlay>
            </LinkBox>
            <MenuList
            onMouseEnter={onServicesOpen}
            onMouseLeave={onServicesClose}
            >
              <AnchorLink
                to="/services#design"
                _hover={{ textDecoration: "none" }}
                title="Design Services"
                className="stripped"
                stripHash
              >
                <MenuItem>Design</MenuItem>
              </AnchorLink>

              <AnchorLink
                to="/services#verification"
                _hover={{ textDecoration: "none" }}
                title="Verification Services"
                className="stripped"
                stripHash
              >
                <MenuItem>Verification</MenuItem>
              </AnchorLink>

              <AnchorLink
                to="/services#manufacturing"
                _hover={{ textDecoration: "none" }}
                title="Manufacturing Services"
                className="stripped"
                stripHash
              >
                <MenuItem>Manufacturing</MenuItem>
              </AnchorLink>

              <AnchorLink
                to="/services#licensing"
                _hover={{ textDecoration: "none" }}
                title="Technology Licensing"
                className="stripped"
                stripHash
              >
                <MenuItem>Technology Licensing</MenuItem>
              </AnchorLink>
            </MenuList>
          </>
        
      </Menu>

      <Menu isOpen={isTechnologyOpen} onClose={onTechnologyClose}>
          <>
            
              <LinkBox>
                <LinkOverlay as={GatsbyLink} to="/technology">
                  <MenuButton
                  as={Button}
                  rightIcon={isTechnologyOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  isActive={isTechnologyOpen}
                  onMouseEnter={onTechnologyOpen}
                  onMouseLeave={onTechnologyClose}
                  bg="none"
                >
                  <NavItem linkTo="/technology" linkText="Technology" />
                </MenuButton>
                </LinkOverlay>
              </LinkBox>
            
            <MenuList
            onMouseEnter={onTechnologyOpen}
            onMouseLeave={onTechnologyClose}
            >
              <Text mb="0" ml="2" fontWeight="600">
                Component Technologies
              </Text>

              <MenuDivider />
              <AnchorLink
                to="/technology#integrated-circuits"
                _hover={{ textDecoration: "none" }}
                title="Integrated Circuits"
                className="stripped"
                stripHash
              >
                <MenuItem>Integrated Circuits</MenuItem>
              </AnchorLink>

              <AnchorLink
                to="/technology#power-and-data-systems"
                _hover={{ textDecoration: "none" }}
                title="Electronic Subsystems"
                className="stripped"
                stripHash
              >
                <MenuItem>Electronic Subsystems</MenuItem>
              </AnchorLink>

              <AnchorLink
                to="/technology#hermetic-packaging"
                _hover={{ textDecoration: "none" }}
                title="Hermetic Packaging"
                className="stripped"
                stripHash
              >
                <MenuItem>Hermetic Packaging</MenuItem>
              </AnchorLink>
              <AnchorLink
                to="/technology#flex-lead-technology"
                _hover={{ textDecoration: "none" }}
                title="Custom Lead Technology"
                className="stripped"
                stripHash
              >
                <MenuItem>Custom Lead Technology</MenuItem>
              </AnchorLink>

              <Text mb="0" ml="2" mt="4" fontWeight="600">
                End-to-End Platforms
              </Text>
              <MenuDivider />

              <AnchorLink
                to="/technology#clinical-investigations"
                _hover={{ textDecoration: "none" }}
                title="Clinical Investgations"
                className="stripped"
                stripHash
              >
                <MenuItem>Clinical Investigations</MenuItem>
              </AnchorLink>

              <AnchorLink
                to="/technology#pre-clinical-research"
                _hover={{ textDecoration: "none" }}
                title="Pre-Clinical Research"
                className="stripped"
                stripHash
              >
                <MenuItem>Pre-Clinical Research</MenuItem>
              </AnchorLink>
            </MenuList>
          </>
      </Menu>

      <Menu isOpen={isNewsOpen} onClose={onNewsClose}>  
          <>
            <LinkBox>
              <LinkOverlay as={GatsbyLink} to="/news-events">
                <MenuButton
                  as={Button}
                  rightIcon={isNewsOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  isActive={isNewsOpen}
                  onMouseEnter={onNewsOpen}
                  onMouseLeave={onNewsClose}
                  bg="none"
                >
                  <NavItem linkText="News &amp; Events" linkTo="/news-events" />
                </MenuButton>
              </LinkOverlay>
            </LinkBox>
            <MenuList
            onMouseEnter={onNewsOpen}
            onMouseLeave={onNewsClose}
            >
              <AnchorLink
                to="/news-events"
                _hover={{ textDecoration: "none" }}
                title="Customer Spotlight"
                className="stripped"
                stripHash
              >
                <MenuItem>Customer Spotlight</MenuItem>
              </AnchorLink>
              <AnchorLink
                to="/news-events#news"
                _hover={{ textDecoration: "none" }}
                title="News"
                className="stripped"
                stripHash
              >
                <MenuItem>News</MenuItem>
              </AnchorLink>
              <AnchorLink
                to="/news-events#events"
                _hover={{ textDecoration: "none" }}
                title="Events"
                className="stripped"
                stripHash
              >
                <MenuItem>Events</MenuItem>
              </AnchorLink>
            </MenuList>
          </>
        
      </Menu>

      <Menu isOpen={isAboutOpen} onClose={onAboutClose}>
          <>
          <LinkBox>
              <LinkOverlay as={GatsbyLink} to="/about-us">
            <MenuButton
              as={Button}
              rightIcon={isAboutOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
              isActive={isAboutOpen}
              onMouseEnter={onAboutOpen}
              onMouseLeave={onAboutClose}
              bg="none"
            >
              <NavItem linkText="About" linkTo="/about-us" />
            </MenuButton>
            </LinkOverlay>
              </LinkBox>
            <MenuList
            onMouseEnter={onAboutOpen}
            onMouseLeave={onAboutClose}
            >
              <AnchorLink
                to="/about-us"
                _hover={{ textDecoration: "none" }}
                title="Company Overview"
                className="stripped"
                stripHash
              >
                <MenuItem>Company Overview</MenuItem>
              </AnchorLink>
              
              <AnchorLink
                to="/about-us#leadership"
                _hover={{ textDecoration: "none" }}
                title="Leadership Team"
                className="stripped"
                stripHash
              >
                <MenuItem>Leadership Team</MenuItem>
              </AnchorLink>
              <AnchorLink title="Careers" to="/careers">
                <MenuItem>Careers</MenuItem>
              </AnchorLink>
            </MenuList>
          </>
      </Menu>
      <Box>
        <ContactModal />
      </Box>
    </Flex>
  )
}

export default StickyNavMenu
