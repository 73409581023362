import React from "react"
import { Box, Link, Flex, Text } from "@chakra-ui/react"
import { ArrowUpIcon } from "@chakra-ui/icons"

import { Link as GatsbyLink } from "gatsby"
import FooterContactModal from "./FooterContactModal"
import styled from '@emotion/styled'

const FooterUpLink = styled(Box)`
    cursor: pointer;
    &:hover {
        background-color: #a9bac9;
    }
    &:hover > div > svg > path {
        fill: white;
    }
`

const FooterNavItem = (props) => {
    return (
    <Box w={{sm: '100%', md: 'auto'}} textAlign={{sm: 'center', md: 'left'}}>
              <Link
                as={GatsbyLink}
                color="white"
                textTransform="uppercase"
                fontWeight="700"
                _hover={{ textDecoration: "none", color: "steel.200" }}
                to={props.linkTo}
                activeClassName="footer-active"
                textAlign={{sm: 'center', md: 'left'}}
                fontSize={{ lg: '.8rem', xl: '1rem'}}
              >
        {props.linkText}
      </Link>
    </Box>
    )
  }

  // Scroll to top
    function ScrollToTop() {
        const scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'smooth'
        }
        window.scrollTo(scrollOptions)
    }

const Footer = () => {
  return (
    <Box as="footer" overflowX="hidden">
      <Box w="100%" h="auto"color="white" bg="steel.700">
            <Flex justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap">
                <Box w={{sm: "100%", md:"30%"}} bg="steel.700" display={{sm: 'none', md: 'block'}}>
                    <Text textTransform="uppercase" mb="0" pl={{sm: 0, md: 6}}
                        textAlign={{sm: 'center', md: 'left'}}
                    >
                        <span style={{fontWeight: '700'}}>
                            IRIS BIOMEDICAL
                        </span>
                        <br />
                        Salt Lake City, Utah, 84106<br />
                        <small>&copy; { new Date().getFullYear()} IRIS Biomedical</small>
                    </Text>
                </Box>

                {/* Mobile  */}
                <Box w={{sm: "100%", md:"30%"}} bg="steel.700" my={{sm: '4'}} display={{sm: 'block', md: 'none'}}>
                    <Text textTransform="uppercase" mb="0" pl={{sm: 0, md: 6}}
                        textAlign={{sm: 'center', md: 'left'}}
                    >
                        <span style={{fontWeight: '700'}}>
                            IRIS BIOMEDICAL
                        </span>
                        <br />
                        Salt Lake City, Utah, 84106<br />
                        <small>&copy; { new Date().getFullYear()} IRIS Biomedical</small>
                    </Text>
                </Box>
                <Box w={{sm: "100%", md:"50%"}} bg="steel.700" my={{sm: '4', md: 0}}>
                    <Flex justifyContent={{sm: 'center', md: 'space-around'}} w="100%" flexWrap="wrap" alignItems="center">
                        <FooterNavItem 
                            linkTo="/services"
                            linkText="Services"
                        />
                        <FooterNavItem 
                            linkTo="/technology"
                            linkText="Technology"
                        />
                        <FooterNavItem 
                            linkTo="/news-events"
                            linkText="News &amp; Events"
                        />
                        <FooterNavItem 
                            linkTo="/about-us"
                            linkText="About"
                        />
                        <FooterContactModal />
                    </Flex>
                </Box>
                <FooterUpLink w={{sm: "100%", md:"5%"}} bg="#BFD1E1" h="100%" py={8} onClick={ScrollToTop} >
                    <Flex justifyContent="center" alignItems="center">
                        <ArrowUpIcon w={6} h={6} color="#2b2b2b" _hover={{color: 'steel.700'}}  />   
                    </Flex>
                    
                </FooterUpLink>
            </Flex>
        </Box>
    </Box>
  )
}

export default Footer
