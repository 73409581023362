import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Link,
    useDisclosure,
    Box
} from "@chakra-ui/react"
import ContactForm from '../ContactForm/ContactForm';

const FooterContactModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    return (
        <Box>
            <Link onClick={onOpen}                
                color="white"
                textTransform="uppercase"
                fontWeight="700"
                _hover={{ textDecoration: "none", color: "steel.200" }} 
                activeClassName="footer-active"
                textAlign={{sm: 'center', md: 'left'}}
                fontSize={{ lg: '.8rem', xl: '1rem'}}
                cursor="pointer"
                m="0">
                Contact
            </Link>

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{zIndex: 'unset'}}>Send Us A Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContactForm />
          </ModalBody>
        </ModalContent>
      </Modal>
        </Box>
    )
}

export default FooterContactModal;