import React from "react"
import PropTypes from "prop-types"
import useSticky from "../hooks/useSticky"

import "./layout.css"
import Header from "./header"

import Footer from "./Footer/Footer"
import StickyHeader from "./StickyHeader/StickyHeader"

const Layout = ( props ) => {
  const { isSticky, element } = useSticky()
  
  return (
    <div className="site">
      <Header {...props} />
      <StickyHeader sticky={isSticky} />
        <main id="main-content">
          <div ref={element}>{props.children}</div>
        </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
